import React, { useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet-async";
import {
  polymaintxnlink,
  polytxnlink,
  q_skingame_info,
  q_skingame_txns,
  q_skingame_vault_skins,
  qiserr,
  qissuccesss,
  q_skingame_burn_validate,
  q_que_txn,
} from "../queries/queries.js";
import { useMetaMaskContext } from "../wrappers/MetaMaskWrapper.js";
import { twMerge } from "tailwind-merge";
import {
  tablecn,
  Img,
  Tag,
  Card,
  InpText,
} from "../components/utilityComps.js";
import { Loader01c } from "../components/anims.js";
import { metamaskimg, polychainimg } from "../utils/links.js";
import { useQueries } from "react-query";
import {
  cdelay,
  dec,
  getv,
  iso_format,
  jstr,
  nils,
  toeth,
  tofeth,
} from "../utils/utils.js";
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBurn,
  faChevronLeft,
  faChevronRight,
  faClose,
  faRefresh,
} from "@fortawesome/free-solid-svg-icons";
import { ethers } from "ethers";
import { SkinGame } from "../contracts/SkinGame/SkinGame.js";
import { SkinCredits } from "../contracts/SkinCredits/SkinCredits.js";
import { PopUp, PopupCloseBtn } from "../components/popup.js";
import moment from "moment";
import { SkinCard } from "./Skins.js";
import { Link } from "react-router-dom";
import FBikeSkin from "../contracts/FBikeSkin/FBikeSkin_Contract.js";
import { contractAddress_list } from "../contracts/contracts";
import { useThirdWebLoginContext } from "./ThirdWebLogin.js";
import { polygon } from "thirdweb/chains";
import { t3_contract_call } from "../contracts/contract_funcs.js";
import { useLayoutContext } from "../components/Layout.js";
import { useAuthContext } from "../wrappers/AuthWrapper.js";

const SkinGameContext = React.createContext({});
export const useSkinGameContext = () => React.useContext(SkinGameContext);

const creditsimg =
  "https://cdn3d.iconscout.com/3d/premium/thumb/coin-5261880-4403225.png";
const boxtypes = _.chain([
  [1, "common", "#55AC3C", "imgs/sksc01.png"],
  [2, "uncommon", "#464C79", "imgs/sksc02.png"],
  [3, "rare", "#E58F3A", "imgs/sksc03.png"],
  [4, "epic", "#BDBCB2", "imgs/sksc04.png"],
  [5, "legendary", "#D07C00", "imgs/sksc05.png"],
  [6, "ultimate", "#8CB8AE", "imgs/sksc06.png"],
  [7, "exclusive", "#8CB8AE", "imgs/sksc06.png"],
])
  .map((e) => {
    let [boxtype, rarity, acc_color, img] = e;
    return { boxtype, rarity, acc_color, img };
  })
  .keyBy("boxtype")
  .value();

const ClosedBox = ({ boxtype }) => {
  const mmcon = useMetaMaskContext();
  const aucon = useAuthContext();
  const { auth, vault, aumode } = aucon;

  const t3con = useThirdWebLoginContext();
  const { thirdweb_client, active_account } = t3con;

  const boxtheme = boxtypes[boxtype];

  const { qo_skingame_txns } = useSkinGameContext();

  const [resp, set_resp] = useState({});
  const [count_loading, set_count_loading] = useState(true);
  const [loading, set_loading] = useState(false);
  const [closed_n, set_closed_n] = useState(0);

  const update_closed = async () => {
    // set_count_loading(true);
    const skinGameContract =
      aumode == "thirdweb"
        ? await SkinGame.get_contract({ nosigner: true, rpc: polygon.rpc })
        : await SkinGame.get_contract();
    let n = await skinGameContract.ownsBoxes(vault, boxtype);
    // console.log("update_closed", { aumode, n });
    set_count_loading(false);
    return set_closed_n(n);
    // return set_closed_n(3);
  };
  const fetenable = aumode == "thirdweb" ? t3con.connected && auth : auth;
  useEffect(() => {
    let interval = null;
    if (fetenable) {
      setTimeout(update_closed, 1 * 1e3);
      interval = setInterval(update_closed, 2 * 60 * 1e3);
    }
    return () => {
      if (interval) clearInterval(interval);
    };
  }, [fetenable, vault]);

  const openbox = async () => {
    set_loading(true);
    let skinGameContract =
      aumode == "thirdweb"
        ? await SkinGame.get_contract({ nosigner: true, rpc: polygon.rpc })
        : await SkinGame.get_contract();

    let resp = null;
    if (aumode == "thirdweb") {
      resp = await t3_contract_call(
        "skingame",
        "openBox",
        [boxtype],
        "txn",
        true,
        { active_account },
      );
    } else {
      resp = await skinGameContract.openBox(boxtype);
    }
    // console.log(resp);
    await q_que_txn({
      hash: resp.hash,
      vault,
      service: "skingame",
      type: "open_box",
    }).queryFn();

    await cdelay(5000);
    set_loading(false);
    setTimeout(update_closed, 5 * 1e3);
    setTimeout(qo_skingame_txns.refetch, 40 * 1e3);
  };

  return (
    <div className=" rounded-md bg-reg fr-cc w-max">
      <Card
        className={twMerge(
          "resp-p-4 pl-[2rem] overflow-hidden w-max text-center bg-dark/20 mb-0",
        )}
      >
        <p className="font-digi resp-text-0 resp-my-2">
          {_.upperCase(boxtheme.rarity)}
        </p>
        <div className="fr-cc">
          <div className="relative xs:w-[5rem] md:w-[12rem] xs:h-[5rem] md:h-[12rem] mx-auto ">
            <div
              style={{
                background: boxtheme.acc_color,
              }}
              className={twMerge(
                "absolute animate-fade-in xs:w-[5rem] md:w-[12rem] xs:h-[5rem] md:h-[12rem] translate-x-[-50%] translate-y-[-50%] top-[50%] left-[50%] z-[5] blur-3xl",
              )}
            ></div>
            <Img
              img={boxtheme.img}
              className={
                "absolute xs:w-[5rem] md:w-[16rem] xs:h-[5rem] md:h-[16rem] z-[10] translate-x-[-20%] translate-y-[-20%]"
              }
            />
          </div>
          <div className="p-4">
            {!auth ? (
              <></>
            ) : count_loading ? (
              <div className="fr-sc my-1">
                <div className="spin-anim">
                  <FontAwesomeIcon className="resp-text-1" icon={faRefresh} />
                </div>
              </div>
            ) : (
              <p className="text-acc0 font-digi resp-text-2">x {closed_n}</p>
            )}
          </div>
        </div>
        {closed_n > 0 ? (
          <div className="fr-sc my-1">
            <div className="flex-1"></div>
            {loading && <Loader01c size="s" />}
            <Tag
              onClick={() => {
                if (loading) return;
                openbox();
              }}
              style={{ backgroundColor: `${boxtheme.acc_color}` }}
              className="-skew-x-12 text-black font-digi"
            >
              Open 1 Box
            </Tag>
          </div>
        ) : (
          <div className="fr-sc my-1">
            <Tag className="opacity-5">-</Tag>
          </div>
        )}
        {!nils(resp.err) && (
          <div className="fr-sc my-4">
            <p className="text-red-400 resp-text--1">{!nils(resp.err)}</p>
          </div>
        )}
      </Card>
    </div>
  );
};

const BuyBoxBtn = ({ base, boxtheme, boxtype }) => {
  const mmcon = useMetaMaskContext();
  const aucon = useAuthContext();
  const { auth, vault, aumode } = aucon;
  const t3con = useThirdWebLoginContext();
  const { thirdweb_client, active_account } = t3con;

  const { qo_skingame_txns, balance_update } = useSkinGameContext();

  const on_buyclick = async () => {};

  const [popup, set_popup] = useState(false);
  const [qty, set_qty] = useState(1);
  const [err, set_err] = useState(null);
  const [msg, set_msg] = useState(null);

  useEffect(() => {
    try {
      let v = qty;
      // if (v > base.available) throw new Error("not enough boxes available");
      v = parseInt(v);
      if (nils(v)) throw new Error("enter valid quantity");
      if (!_.inRange(v, 1, 25.001))
        throw new Error("min:1 max:25 boxes can be bought at a time");
      else set_err(null);
    } catch (err) {
      set_err(err.message);
    }
  }, [qty]);

  const [loading, set_loading] = useState(false);
  const clickpayment = async () => {
    try {
      set_loading(true);
      const topay = parseFloat(base.box_price) * qty;

      const skinGameContract =
        aumode == "thirdweb"
          ? await SkinGame.get_contract({ nosigner: true, rpc: polygon.rpc })
          : await SkinGame.get_contract();
      const tokenContract =
        aumode == "thirdweb"
          ? await SkinCredits.get_contract({ nosigner: true, rpc: polygon.rpc })
          : await SkinCredits.get_contract();

      let balance = await tokenContract.balanceOf(vault);
      balance = parseFloat(toeth(balance));
      if (balance < topay) throw new Error("not enough balance");

      let resp = null;
      if (aumode == "thirdweb") {
        resp = await t3_contract_call(
          "skingame",
          "buyBox",
          [qty, boxtype],
          "txn",
          true,
          { active_account },
        );
      } else resp = await skinGameContract.buyBox(qty, boxtype);

      await q_que_txn({
        hash: resp.hash,
        vault,
        service: "skingame",
        type: "buy_box",
      }).queryFn();

      set_err(null);

      await cdelay(5000);

      /*   setTimeout(() => {
        upd_closed_n();
      }, 5 * 1e3); */

      setTimeout(qo_skingame_txns.refetch, 60 * 1e3);
      setTimeout(balance_update, 20 * 1e3);

      set_loading(false);
      set_popup(false);
    } catch (err) {
      let errmsg = !nils(err.reason) ? `Chain: ${err.reason}` : err.message;
      set_err(errmsg);
      await cdelay(2000);
      set_loading(false);
    }
  };

  return (
    <>
      <Tag
        onClick={() => {
          set_popup(true);
        }}
        style={{
          backgroundColor: `${boxtheme.acc_color}40`,
          border: `2px solid ${boxtheme.acc_color}`,
        }}
        className={twMerge(boxtheme.color)}
      >
        Buy Now
      </Tag>

      <PopUp openstate={popup} overlayclose={false}>
        <div className="fr-sc">
          <div className="flex-1"></div>
          <PopupCloseBtn closepopup={() => set_popup(false)} />
        </div>
        <Card className={"bg-r2dark/40 border border-acc4 min-w-[30rem]"}>
          <div className="fr-cc"></div>
          {!nils(err) && (
            <p className="my-2 p-2 text-red-300 w-full rounded-md border border-red-300">
              {err}
            </p>
          )}
          {!nils(msg) && (
            <p className="my-2 p-2 text-acc0 w-full rounded-md border border-acc0">
              {msg}
            </p>
          )}
          <div className="mx-auto w-max">
            <div className="fr-cc gap-2">
              <p className="resp-text--1">{"Select Quantity"}</p>
              <InpText
                id="inp_qty"
                contprops={{ className: "w-max bg-dark" }}
                inpprops={{ className: "w-[4rem]" }}
                def_val={qty}
                setter={() => {
                  if (loading) return;
                  let v = document.getElementById("inp_qty").value;
                  v = parseInt(v);
                  try {
                    if (v > base.available)
                      throw new Error("not enough boxes available");

                    if (nils(v)) throw new Error("enter valid quantity");
                    if (!_.inRange(v, 1, 25.001))
                      throw new Error(
                        "min:1 max:25 boxes can be bought at a time",
                      );
                    else {
                      set_qty(v);
                      set_err(null);
                    }
                  } catch (err) {
                    set_err(err.message);
                  }
                }}
              />
            </div>
            <hr className="my-2" />
            <table className={tablecn.table_cn}>
              <tbody>
                <tr className={tablecn.tr_cn}>
                  <td className={tablecn.td_cn}>
                    <p className="whitespace-pre-wrap">Quantity</p>
                  </td>
                  <td className={tablecn.td_cn}>
                    <p className="whitespace-pre-wrap">{qty} qty.</p>
                  </td>
                </tr>
                <tr className={tablecn.tr_cn}>
                  <td className={tablecn.td_cn}>
                    <p className="whitespace-pre-wrap">LootBox Cost</p>
                  </td>
                  <td className={tablecn.td_cn}>
                    <p className="whitespace-pre-wrap">
                      {dec(base.box_price, 2)} {base.token}
                    </p>
                  </td>
                </tr>
                <tr className={tablecn.tr_cn}>
                  <td className={tablecn.td_cn}>
                    <p className="whitespace-pre-wrap">Total</p>
                  </td>
                  <td className={tablecn.td_cn}>
                    <p className="whitespace-pre-wrap">
                      {`${dec(parseFloat(base.box_price) * qty, 2)} ${
                        base.token
                      }`}
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>

            <div className="fr-cc my-2">
              {loading ? (
                <>
                  <Loader01c size="s" />
                  <Tag className="">
                    {aumode == "thirdweb"
                      ? "sending txn..."
                      : "please confirm transaction on MetaMask..."}
                  </Tag>
                </>
              ) : (
                <Tag
                  onClick={() => {
                    clickpayment();
                  }}
                  className="fr-cc gap-2 bg-acc0/40 text-white transform -skew-x-12"
                >
                  <span>Pay Now</span>
                </Tag>
              )}
            </div>
          </div>
        </Card>
      </PopUp>
    </>
  );
};

const BuyBox = ({ boxtype }) => {
  const { info } = useSkinGameContext();
  const mmcon = useMetaMaskContext();
  const aucon = useAuthContext();
  const { auth, vault, aumode } = aucon;

  const laycon = useLayoutContext();

  const base = _.find(info, (e) => e.boxtype == boxtype);
  const boxtheme = boxtypes[boxtype];

  return (
    <>
      <div className="w-full">
        <div className="mx-auto w-max">
          <div className="fc-cc w-full flex-wrap relative">
            <div
              style={{
                backgroundColor: `${boxtheme.acc_color}`,
              }}
              className={twMerge(
                "md:w-[8rem] md:h-[8rem]  md:top-[2rem] md:left-[0.5rem]",
                "xs:w-[8rem] xs:h-[12rem]  xs:top-[1rem] xs:left-[0.1rem]",
                "absolute translate-x-[50%] translate-y-[50%] z-[0] blur-3xl",
              )}
            ></div>
            <div
              style={{
                background: `linear-gradient(${boxtheme.acc_color}40 0%, rgba(100, 100, 100, 0) 100%)`,
              }}
              className="mint-box-container xs:mt-[50px] md:mt-[133px] xs:lg:w-[8rem] lg:w-[20rem]"
            >
              <div
                className={twMerge(
                  "md:top-[-6.5rem] md:w-[16rem]",
                  "xs:top-[-2rem] xs:w-[6rem]",
                  "img-obey-cont rotate-[0deg] absolute",
                )}
              >
                <img src={boxtheme.img} />
              </div>
              <div className="xs:h-[5rem] md:h-[7rem]"></div>
              <p className="resp-text-1 font-digi">
                <span className="italic">{_.upperCase(boxtheme.rarity)}</span>
              </p>
              <p className="resp-text-1 font-mon font-thin">Mint Skin Box</p>

              <hr className="w-full border-white/40 my-2" />

              <div className="grid grid-cols-2 w-full">
                <p className="resp-text--1 text-center font-mon font-thin">
                  {"Price"}
                </p>
                <p className="resp-text--1 text-center font-digi border-l border-white/40">
                  SGC
                </p>
              </div>
              <div className="fr-cc">
                <div className="">
                  <span
                    style={{ color: boxtheme.acc_color }}
                    className={twMerge("resp-text-1 font-digi")}
                  >
                    {dec(base.box_price, 2)}
                  </span>
                </div>

                <div className=""></div>
              </div>

              {auth == false && (
                <Tag
                  onClick={() => {
                    laycon.open_loginpop();
                  }}
                  className=" mt-4 mb-2 px-4 py-2 bg-acc0/80 text-black fr-cc gap-2"
                >
                  {<span>Connect Wallet</span>}
                </Tag>
              )}

              {auth == true && <BuyBoxBtn {...{ base, boxtheme, boxtype }} />}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const BurnSkinRow = ({ skin }) => {
  const mmcon = useMetaMaskContext();
  const { mmaccount: vault, auth, aumode } = mmcon;
  const t3con = useThirdWebLoginContext();
  const { thirdweb_client, active_account } = t3con;

  const { qo_skingame_txns, balance_update } = useSkinGameContext();

  const [resp, set_resp] = useState([null, null]);
  const [loading, set_loading] = useState(false);
  const burn_skin = async (skinid) => {
    try {
      set_loading(true);
      set_resp([null, null]);

      set_resp(["msg", `Checking if skin is burnable...`]);

      let burnable = await q_skingame_burn_validate({ skinid }).queryFn();
      burnable = getv(burnable, "result.valid");
      if (burnable !== true)
        throw new Error("Skin is not eligible for burning");

      await cdelay(1000);
      if (aumode == "thirdweb") set_resp(["msg", `Burning Skin...`]);
      else set_resp(["msg", `Please confirm transaction on MetaMask...`]);

      try {
        const skinGameContract =
          aumode == "thirdweb"
            ? await SkinGame.get_contract({ nosigner: true, rpc: polygon.rpc })
            : await SkinGame.get_contract();

        const skinContract =
          aumode == "thirdweb"
            ? await FBikeSkin.get_contract({ nosigner: true, rpc: polygon.rpc })
            : await FBikeSkin.get_contract();

        let resp0 = null;
        if (aumode == "thirdweb") {
          resp0 = await t3_contract_call(
            "fbikeskin",
            "approve",
            [contractAddress_list.skingamead, skinid],
            "txn",
            true,
            { active_account },
          );
        } else {
          resp0 = await skinContract.contract.approve(
            contractAddress_list.skingamead,
            skinid,
          );
        }
        // console.log(resp0);
        let resp = null;
        if (aumode == "thirdweb") {
          resp = await t3_contract_call(
            "skingame",
            "burnSkin",
            [skinid],
            "txn",
            true,
            { active_account },
          );
        } else {
          resp = await skinGameContract.burnSkin(skinid);
        }
        set_resp(["success", "Skin Burnt"]);

        await q_que_txn({
          hash: resp.hash,
          vault,
          service: "skingame",
          type: "burn_skin",
        }).queryFn();

        setTimeout(qo_skingame_txns.refetch, 30 * 1e3);
      } catch (err) {
        if (err.reason) {
          if (err.reason.length > 100)
            set_resp(["error", err.reason.slice(0, 100)]);
          else set_resp(["error", err.reason]);
        } else set_resp(["error", err.message]);
      }
      await cdelay(1000);
      setTimeout(qo_skingame_txns.refetch, 60 * 1e3);
      setTimeout(balance_update, 20 * 1e3);
      set_loading(false);
    } catch (err) {
      set_resp(["error", err.message]);
      await cdelay(2000);
      set_loading(false);
    }
  };
  const skinid = skin.skinid;

  const [burn_popup, set_burn_popup] = useState(false);

  return (
    <tr className="thintdrow" key={skin.skinid}>
      <td>
        <span className="resp-text--1">#{skin.skinid}</span>
      </td>
      <td>
        <span className="resp-text--1">{skin.rarity}</span>
      </td>
      <td colSpan={2}>
        <div className="fc-ss">
          <p className="resp-text--1">{skin.skin}</p>
        </div>
      </td>
      <td>
        <div className="fr-sc gap-1">
          {loading ? (
            <>
              <Loader01c size="s" />
            </>
          ) : (
            <Tag
              onClick={() => set_burn_popup(true)}
              className="bg-red-500 fr-cc resp-gap-2"
            >
              <FontAwesomeIcon icon={faBurn} />
              <span>Burn</span>
            </Tag>
          )}
        </div>
      </td>
      <>
        <PopUp
          openstate={burn_popup}
          overlayclose={false}
          onclose={() => {
            set_burn_popup(false);
          }}
          wrapcn={twMerge("top-[5rem] left-[50%] z-[2001]")}
          innercn={twMerge(" translate-x-[-50%] translate-y-[0%]")}
        >
          <Card
            className={"bg-r2dark/30 backdrop-blur-lg xs:w-[70vw] lg:w-[30rem]"}
          >
            <div className="fr-sc">
              <div className="flex-1"></div>
              <PopupCloseBtn closepopup={() => set_burn_popup(false)} />
            </div>
            <div className="fc-cc resp-gap-2">
              <p className="resp-text--1">
                Are you Sure you want to Burn Skin#{skinid}?
              </p>
              <p className="resp-text--1">
                {skin.rarity}-[{skin.name}]
              </p>

              {loading ? (
                <>
                  <Loader01c size="s" />
                </>
              ) : (
                <Tag
                  onClick={() => burn_skin(skinid)}
                  className="bg-red-500 fr-cc resp-gap-2"
                >
                  <FontAwesomeIcon icon={faBurn} />
                  <span>Burn Skin</span>
                </Tag>
              )}
              <div className="w-full">
                {!nils(resp) && !nils(resp[0]) && (
                  <>
                    {resp[0] == "success" && (
                      <p className="text-green-400 resp-text--2">
                        {"Skin Burnt"}
                      </p>
                    )}
                    {resp[0] == "error" && (
                      <p className="text-red-400 resp-text--2">{`Error:${resp[1]}`}</p>
                    )}
                    {resp[0] == "msg" && (
                      <p className="text-yellow-400 resp-text--2">{`${resp[1]}`}</p>
                    )}
                  </>
                )}
              </div>
            </div>
          </Card>
        </PopUp>
      </>
    </tr>
  );
};

const Burner = () => {
  const mmcon = useMetaMaskContext();
  // const { mmaccount: vault, auth } = mmcon;
  const aucon = useAuthContext();
  const { auth, vault, aumode } = aucon;
  const [popup, set_popup] = useState(false);
  const [qoskins] = useQueries([
    q_skingame_vault_skins({ vault }, { enabled: popup == true }),
  ]);
  const skins = useMemo(() => {
    if (qissuccesss(qoskins)) {
      return getv(qoskins, "data.result", []);
    }
    return [];
  }, [qoskins.dataUpdatedAt]);

  return (
    <div className="">
      <Tag
        onClick={() => {
          set_popup(true);
        }}
        className="bg-acc0/40 italic text-white font-digi"
      >
        Burn Skin
      </Tag>
      {
        <PopUp
          openstate={popup}
          overlayclose={false}
          onclose={() => {
            set_popup(false);
          }}
          wrapcn={twMerge("top-[5rem] left-[50%]")}
          innercn={twMerge(" translate-x-[-50%] translate-y-[0%]")}
        >
          <Card
            className={
              "card-basic-bg xs:w-full md:w-[50rem] max-w-[95rem] relative"
            }
          >
            <div className="fr-cc">
              <div className="flex-1"></div>
              <Tag
                onClick={() => set_popup(false)}
                className={twMerge(
                  "fc-cc rounded-full bg-red-500 ",
                  "absolute md:right-[-1rem] md:top-[-1rem] md:w-[2rem] md:h-[2rem]",
                  "absolute xs:right-[-0.5rem] xs:top-[-0.5rem] xs:w-[1rem] xs:h-[1rem]",
                )}
              >
                <FontAwesomeIcon icon={faClose} />
              </Tag>
            </div>

            {qoskins.isLoading ? (
              <Loader01c />
            ) : qiserr(qoskins) ? (
              <p className="text-red-400">{qiserr(qoskins)}</p>
            ) : qissuccesss(qoskins) && _.isEmpty(skins) ? (
              <p className="text-yellow-400">No Skins to Burn</p>
            ) : qissuccesss(qoskins) && !_.isEmpty(skins) ? (
              <div className="xs:h-[70vh] lg:h-[60vh] overflow-auto">
                <table className={tablecn.table_cn}>
                  <thead>
                    <tr className="thintdrow font-digi resp-text--1 text-acc0">
                      <td>SkinID</td>
                      <td>Rarity</td>
                      <td>Skin Name</td>
                    </tr>
                  </thead>
                  <tbody>
                    {skins.map((skin) => {
                      return <BurnSkinRow skin={skin} key={skin.skinid} />;
                    })}
                  </tbody>
                </table>
              </div>
            ) : (
              <></>
            )}
          </Card>
        </PopUp>
      }
    </div>
  );
};

const TopBuyBoxesBanner = () => {
  const mmcon = useMetaMaskContext();
  // const { mmaccount: vault, auth } = mmcon;
  const aucon = useAuthContext();
  const { auth, vault, aumode } = aucon;

  const { info } = useSkinGameContext();
  const [selview, set_selview] = useState(1);
  return (
    <div>
      <div className="w-full overflow-auto bg-black select-none">
        <div className="w-full grid xs:grid-cols-1 lg:grid-cols-2 ">
          <div className="w-max mx-auto fc-cc xs:min-h-[10vh] lg:min-h-[50vh] max-h-[800px]">
            <table className={twMerge(tablecn.table_cn, "")}>
              <thead>
                <tr className="thintdrow font-digi resp-text--1">
                  <th></th>
                  <th>Rarity</th>
                  <th className="">
                    <p className="resp-px-2">Burn Credits</p>
                  </th>
                  <th className="">
                    <p className="resp-px-2">Box Price</p>
                  </th>
                </tr>
              </thead>
              <tbody>
                {info.map((e) => {
                  return (
                    <tr
                      onClick={() => {
                        set_selview(e.boxtype);
                      }}
                      key={e.boxtype}
                      className={twMerge(
                        "thintdrow font-digi resp-text--1",
                        selview == e.boxtype ? "bg-dark text-acc0" : "",
                        "hover:bg-dark hover:text-acc0 cursor-pointer",
                      )}
                    >
                      <td>{e.boxtype}</td>
                      <td className="">{_.upperCase(e.rarity)}</td>
                      <td className="">
                        <p className="text-right">{e.burn_credits}</p>
                      </td>
                      <td className="">
                        <p className="text-right">
                          {e.box_price == 0 || nils(e.box_price)
                            ? "--"
                            : e.box_price}
                        </p>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <div className="my-2 fr-sc">{auth && <Burner />}</div>
          </div>

          <div className="w-max mx-auto xs:min-h-[10vh] lg:min-h-[50vh] max-h-[800px] fc-cc">
            <div className="fr-cc resp-gap-2">
              <Tag
                onClick={() => {
                  set_selview(((selview - 2 + info.length) % info.length) + 1);
                }}
                className="resp-px-4 resp-py-4 hover:bg-acc0/20"
              >
                <FontAwesomeIcon icon={faChevronLeft} />
              </Tag>
              <BuyBox {...{ boxtype: selview }} />
              <Tag
                onClick={() => {
                  set_selview((selview % info.length) + 1);
                }}
                className="resp-px-4 resp-py-4 hover:bg-acc0/20"
              >
                <FontAwesomeIcon icon={faChevronRight} />
              </Tag>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const CreditsBalance = () => {
  const { auth, balance, set_balance, balance_update } = useSkinGameContext();

  useEffect(() => {
    let interval = null;
    if (auth) {
      setTimeout(balance_update, 1 * 1e3);
      interval = setInterval(balance_update, 2 * 60 * 1e3);
    }
    return () => {
      if (interval) clearInterval(interval);
    };
  }, [auth]);

  return (
    <Tag
      style={{
        backgroundColor: `#FFBD1940`,
        border: `2px solid #FFBD19`,
      }}
      className="xs:rounded-full lg:rounded-full fr-sc gap-1 resp-text--1 xs:px-2 lg:px-2"
    >
      <div className="xs:w-[1rem] xs:h-[1rem] xs:w-[2rem] xs:h-[2rem]">
        <Img img={creditsimg} />
      </div>
      {!auth ? (
        <span>--</span>
      ) : auth && nils(balance) ? (
        <div className="spin-anim">
          <FontAwesomeIcon icon={faRefresh} />
        </div>
      ) : (
        <span>{dec(balance, 2)}</span>
      )}
    </Tag>
  );
};

const OwnedBoxesList = () => {
  return (
    <div className="fr-cc gap-2 flex-wrap">
      {[1, 2, 3, 4, 5, 6].map((i) => {
        let e = boxtypes[i];
        return <ClosedBox {...{ key: i, boxtype: e.boxtype }} />;
      })}
    </div>
  );
};

const OpenedNFT = ({ tx }) => {
  const hash = getv(tx, "connects_post.mint_txn");
  const date = getv(tx, "connects_post.mint_date");
  const skin = getv(tx, "connects_post.skin");

  const txrow = (
    <>
      {nils(hash) ? (
        <>
          <Loader01c size="s" />
          <span className="text-acc0 resp-text-0">Awaiting Mint...</span>
        </>
      ) : (
        <>
          <Link
            className="fr-sc gap-1"
            target="_blank"
            to={polymaintxnlink(hash)}
          >
            <Img
              className={twMerge(
                "xs:w-[1.2rem] xs:h-[1.2rem]",
                "md:w-[2rem] md:h-[2rem]",
              )}
              img={polychainimg}
            />
            <span className="text-purple-300 resp-text-0">
              {hash.slice(0, 5)}..
            </span>
          </Link>
          <span className="resp-text-0">Minted on {iso_format(date)}</span>
        </>
      )}
    </>
  );

  return (
    <>
      <div className=" bg-dark rounded-md ">
        <div className="fr-cc gap-2 p-2 px-4 resp-text-0 xs:hidden md:flex">
          <span>TokenID: {skin.skinid ?? "--"}</span>
          <div className="flex-1"></div>
          {txrow}
        </div>

        <div className="fc-ss gap-2 p-2 px-4 resp-text-0 xs:flex md:hidden">
          <span className="resp-my-2">TokenID: {skin.skinid ?? "--"}</span>
          {txrow}
        </div>

        <SkinCard
          {...{
            skin: skin.skin,
            rarity: skin.rarity,
            viewdets: true,
          }}
        />
      </div>
    </>
  );
};

const TxRow = ({ tx }) => {
  const [nftspopup, set_nftspopup] = useState(false);

  let date =
    moment().diff(tx.date, "seconds") < 5 * 60
      ? moment(tx.date).fromNow()
      : iso_format(tx.date, "DD-MMM YY, h:mm:ss a");
  let hash = tx.id.split(":")[0];

  const td_amt = (
    <div className="fr-sc resp-gap-1 resp-text--2">
      <div className="flex-1"></div>
      <div className="xs:w-[0.8rem] lg:w-[1.6rem]">
        <Img img={creditsimg} />
      </div>
      {tx.type == "skin_burn" ? (
        <>
          <span className="text-green-400">
            +{dec(parseFloat(getv(tx, `connects.amt`) ?? 0), 0)} SGC
          </span>
        </>
      ) : (
        <>
          <span>{dec(parseFloat(getv(tx, `connects.amt`) ?? 0), 0)} SGC</span>
        </>
      )}
    </div>
  );

  const td_polyimg = (
    <Tag redirect={polymaintxnlink(hash)} className="xs:w-[1.5rem] lg:w-[3rem]">
      <Img img={polychainimg} />
    </Tag>
  );

  const td_names = <div className="fc-ss resp-gap-1 resp-text--2"></div>;

  const td_type = (
    <span className="resp-text--2">
      {_.chain(tx.type).split("_").map(_.upperCase).join(" ").value()}
    </span>
  );

  const td_date = <span className="resp-text--2">{date}</span>;

  const qty = getv(tx, "connects.qty");
  const td_inout = (
    <>
      {tx.type == "skin_burn" && (
        <Tag className="bg-red-500/20 text-white">
          {getv(tx, "connects.rarity")}#{getv(tx, "connects.skinid")} Burned
        </Tag>
      )}
      {tx.type == "box_minted" && (
        <Tag className="bg-purple-500/20 text-white">{qty} boxes Minted</Tag>
      )}
      {tx.type == "box_minted_free" && (
        <Tag className="bg-pink-500/20 text-white">
          {qty} boxes Minted [FREE]
        </Tag>
      )}
      {tx.type == "box_opened" && (
        <Tag className="bg-yellow-500/20 text-white">1 box Opened</Tag>
      )}
    </>
  );

  const ls = getv(tx, "connects_post.skin");

  const td_view = (
    <>
      {tx.type == "box_opened" && (
        <>
          <Tag
            onClick={() => {
              set_nftspopup(true);
            }}
            className="bg-acc0/40 transform -skew-x-12 text-white gap-1 fr-cc"
          >
            <span>View NFTs</span>
            <FontAwesomeIcon icon={faChevronRight} />
          </Tag>

          <PopUp
            wrapcn={twMerge("top-[5rem] left-[50%]")}
            innercn={twMerge(" translate-x-[-50%] translate-y-[0%]")}
            openstate={nftspopup}
            overlayclose={false}
          >
            <Card className={"xs:w-full md:w-[50rem] max-w-[95rem] relative"}>
              <div className="fr-cc">
                <div className="flex-1"></div>
                <Tag
                  onClick={() => set_nftspopup(false)}
                  className={twMerge(
                    "fc-cc rounded-full bg-red-500 ",
                    "absolute md:right-[-1rem] md:top-[-1rem] md:w-[2rem] md:h-[2rem]",
                    "absolute xs:right-[-0.5rem] xs:top-[-0.5rem] xs:w-[1rem] xs:h-[1rem]",
                  )}
                >
                  <FontAwesomeIcon icon={faClose} />
                </Tag>
              </div>
              <OpenedNFT tx={tx} />;
            </Card>
          </PopUp>
        </>
      )}
    </>
  );

  return (
    <>
      <tr className={"thintdrow xs:hidden md:block"}>
        <td className={""}>{td_polyimg}</td>
        <td className={""}>{td_type}</td>
        <td className={""}>{td_amt}</td>
        <td className={""}>{td_date}</td>
        <td className={""}>{td_inout}</td>
        <td className={""}>{td_view}</td>
      </tr>
      <tr
        className={"thintdrow xs:block md:hidden border-b border-transparent"}
      >
        <td className={""} colSpan={2}>
          {td_polyimg}
        </td>
        <td className={""} colSpan={2}>
          {td_amt}
        </td>
        <td className={""} colSpan={2}>
          {td_date}
        </td>
      </tr>
      <tr className={"thintdrow xs:block md:hidden border-b border-acc0"}>
        <td className="" colSpan={6}>
          <div className="fr-sc">
            {td_inout}
            {td_view}
          </div>
        </td>
      </tr>
    </>
  );
};

const Transactions = () => {
  const { txns } = useSkinGameContext();

  return (
    <Card className={"mx-auto bg-r2reg/40 xs:w-full md:w-max  overflow-auto"}>
      {_.isEmpty(txns) ? (
        <p className="resp-text--1">No Transactions Yet</p>
      ) : (
        <table className={tablecn.table_cn}>
          <tbody>
            {(txns || []).map((tx) => {
              return <TxRow tx={tx} />;
            })}
          </tbody>
        </table>
      )}
    </Card>
  );
};

export const SkinGamePage = () => {
  const mmcon = useMetaMaskContext();
  const aucon = useAuthContext();
  const { vault, auth, aumode } = aucon;
  const { thirdweb_client, active_account } = useThirdWebLoginContext();

  const [qo_info] = useQueries([q_skingame_info()]);
  const info = useMemo(() => {
    if (qissuccesss(qo_info)) return getv(qo_info, "data.result");
    return null;
  }, [qo_info.dataUpdatedAt]);

  const [qo_skingame_txns] = useQueries([q_skingame_txns({ vault })]);
  const txns = useMemo(() => {
    if (qissuccesss(qo_skingame_txns))
      return getv(qo_skingame_txns, "data.result");
    return null;
  }, [qo_skingame_txns.dataUpdatedAt]);

  /*  useEffect(() => {
    console.log("skingame info", info);
  }, [jstr(info)]); */

  useEffect(() => {}, []);

  const [balance, set_balance] = useState(undefined);
  const balance_update = async () => {
    const tokenContract =
      aumode == "thirdweb"
        ? await SkinCredits.get_contract({ nosigner: true, rpc: polygon.rpc })
        : await SkinCredits.get_contract();
    let balance = await tokenContract.balanceOf(vault);
    balance = parseFloat(tofeth(balance));
    set_balance(balance);
  };

  const sgcon = {
    auth,
    vault,

    qo_info,
    info,

    qo_skingame_txns,
    txns,

    balance,
    balance_update,
  };
  return (
    <SkinGameContext.Provider value={sgcon}>
      <Helmet>
        <title>Skin Game || DNA Racing</title>
      </Helmet>

      <div className="relative">
        <div className="xs:block lg:hidden bg-black">
          <div className="fr-sc">
            <div className="flex-1"></div>
            <CreditsBalance />
          </div>
        </div>
        {qo_info.isLoading ? (
          <Loader01c />
        ) : qiserr(qo_info) ? (
          <p className="text-center resp-text-1">{qiserr(qo_info)}</p>
        ) : qissuccesss(qo_info) ? (
          <TopBuyBoxesBanner />
        ) : (
          <></>
        )}

        {auth && (
          <div className="home-container">
            <div className="xs:hidden lg:block">
              <div className="absolute top-[2rem] right-[5rem]">
                <CreditsBalance />
              </div>
            </div>

            <div className="h-[2rem]"></div>
            <OwnedBoxesList />
            <div className="h-[2rem]"></div>
            <p className="text-acc0 italic  resp-text-1">Transactions</p>
            <Transactions />
          </div>
        )}
        <div className="h-[5rem]"></div>
      </div>
    </SkinGameContext.Provider>
  );
};
