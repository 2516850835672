import {
  faCheck,
  faChevronRight,
  faClose,
  faQuestion,
  faQuestionCircle,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  Array2D_Tablecn,
  BikeCard,
  Card,
  Img,
  InpText,
  MiniStatCard,
  Tag,
} from "../components/utilityComps";
import { metamaskimg, polychainimg, polyimg, wethimg } from "../utils/links";
import { useMetaMaskContext } from "../wrappers/MetaMaskWrapper.js";
import { Loader01c } from "../components/anims.js";
import { PopUp } from "../components/popup";
import { twMerge } from "tailwind-merge";
import { useQueries } from "react-query";
import {
  q_lootbox_base,
  q_lootbox_buy_box,
  q_lootbox_validate_purchase,
  q_lootbox_boxes,
  qiserr,
  qissuccess,
  qissuccesss,
  q_corelootbox_v2_base,
  q_corelootbox_v2_txns,
  polytxnlink,
  q_corelootbox_v2_lootnfts,
  polymaintxnlink,
  q_que_txn,
  q_corelootbox_v2_signup_request,
} from "../queries/queries.js";
import {
  cdelay,
  dec,
  from_time_mini,
  getv,
  iso,
  iso_format,
  jstr,
  nano,
  nils,
  toeth,
  tofeth,
  to_time_mini,
} from "../utils/utils.js";
import { transfer_tokens } from "../queries/chainreq.js";
import _ from "lodash";
import { extract_inp, Input, set_val_inp } from "../components/input.js";
import CoreLootbox_v2 from "../contracts/CoreLootbox_v2/CoreLootbox_v2_Contract.js";
import WETH_MockToken from "../contracts/WETH_MockToken/WETH_MockTokenContract.js";
import { ethers } from "ethers";
import { Link } from "react-router-dom";
import moment from "moment";
import { Helmet } from "react-helmet-async";
import { useAppContext, useNowContext } from "../App";
import { useThirdWebLoginContext } from "./ThirdWebLogin";
import { polygon } from "thirdweb/chains";
import {
  mm_asset_signer,
  t3_asset_signer,
  t3_contract_call,
} from "../contracts/contract_funcs";
import { ContactTag } from "./HomePage";
import { faDiscord, faTwitter } from "@fortawesome/free-brands-svg-icons";
import { useAuthContext } from "../wrappers/AuthWrapper";
import { useLayoutContext } from "../components/Layout";

const st_countdown = "2024-07-07T01:00:00Z";
const ed_countdown = "2024-07-07T03:00:00Z";

const MintContext = createContext({});
export const useMintContext = () => useContext(MintContext);

const Buy = ({ base }) => {
  const aucon = useAuthContext();
  const { auth, vault, aumode } = aucon;
  const t3con = useThirdWebLoginContext();
  const { thirdweb_client, active_account } = t3con;

  const { qotxns, upd_closed_n } = useMintContext();

  const on_buyclick = async () => {};

  const [popup, set_popup] = useState(false);
  const [qty, set_qty] = useState(1);
  const [err, set_err] = useState(null);
  const [msg, set_msg] = useState(null);

  // useEffect(() => {
  //   try {
  //     let v = qty;
  //     if (v > base.available) throw new Error("not enough boxes available");
  //     v = parseInt(v);
  //     if (nils(v)) throw new Error("enter valid quantity");
  //     if (!_.inRange(v, 1, 25.001))
  //       throw new Error("min:1 max:25 boxes can be bought at a time");
  //     else set_err(null);
  //   } catch (err) {
  //     set_err(err.message);
  //   }
  // }, [qty]);

  const [loading, set_loading] = useState(false);
  const clickpayment = async () => {
    try {
      set_loading(true);
      const topay = base.price * qty;

      const token = "weth";
      const lootcon =
        aumode === "thirdweb"
          ? await CoreLootbox_v2.get_contract({
              rpc: polygon.rpc,
              nosigner: true,
            })
          : await CoreLootbox_v2.get_contract();
      const paycon =
        aumode === "thirdweb"
          ? await t3_asset_signer(token)
          : await mm_asset_signer(token);

      const balance = await paycon.getBalance(vault);
      balance = parseFloat(tofeth(balance));
      if (balance < topay) throw new Error("not enough balance");

      try {
        const allowance = await paycon.allowance(lootcon.contractAddress);
        if (parseFloat(ethers.formatEther(allowance)) < parseFloat(topay)) {
          let approval = null;
          if (aumode === "thirdweb") {
            approval = await t3_contract_call(
              token,
              "approve",
              [lootcon.contractAddress, toeth(topay)],
              "txn",
              true,
              { active_account },
            );
            await cdelay(5 * 1e3);
          } else {
            approval = await paycon.approve(
              lootcon.contractAddress,
              ethers.parseEther(topay.toString()),
            );
            approval = await approval.wait();
            await cdelay(5 * 1e3);
          }
        }

        let resp = null;
        if (aumode === "thirdweb") {
          resp = await t3_contract_call(
            "corelootboxv2",
            "buyLootbox",
            [qty],
            "txn",
            true,
            { active_account },
          );
        } else {
          await lootcon.buyLootbox(qty);
          resp = await resp.wait();
        }
        console.log(resp);

        await q_que_txn({
          hash: resp.hash,
          vault,
          service: "corelootbox",
          type: "mint",
        }).queryFn();
      } catch (err) {
        let errmsg = !nils(err.reason) ? err.reason : err.message;
        let n = 200;
        if (errmsg.length > n) errmsg = errmsg.slice(0, n);
        throw new Error(errmsg);
      }

      set_err(null);

      await cdelay(5000);

      setTimeout(() => {
        upd_closed_n();
      }, 5 * 1e3);

      setTimeout(() => {
        qotxns.refetch();
      }, 8 * 1e3);

      set_loading(false);
      set_popup(false);
    } catch (err) {
      set_err(err.message);
      set_loading(false);
    }
  };

  return (
    <>
      <Tag
        onClick={() => {
          set_popup(true);
        }}
        className={twMerge("bg-acc4 text-black")}
      >
        Buy Now
      </Tag>

      <PopUp openstate={popup} overlayclose={false}>
        <Card className={"bg-dark min-w-[30rem]"}>
          <div className="fr-cc"></div>
          {!nils(err) && (
            <p className="my-2 p-2 text-red-300 w-full rounded-md border border-red-300">
              {err}
            </p>
          )}
          {!nils(msg) && (
            <p className="my-2 p-2 text-acc4 w-full rounded-md border border-acc4">
              {msg}
            </p>
          )}
          <div className="mx-auto w-max">
            <div className="fr-cc gap-2">
              <p>{"Select Quantity"}</p>
              <InpText
                id="inp_qty"
                contprops={{ className: "w-max bg-dark" }}
                inpprops={{ className: "w-[4rem]" }}
                def_val={qty}
                setter={() => {
                  if (loading) return;
                  let v = document.getElementById("inp_qty").value;
                  v = parseInt(v);
                  try {
                    if (v > base.available)
                      throw new Error("not enough boxes available");

                    if (nils(v)) throw new Error("enter valid quantity");
                    if (!_.inRange(v, 1, 25.001))
                      throw new Error(
                        "min:1 max:25 boxes can be bought at a time",
                      );
                    else {
                      set_qty(v);
                      set_err(null);
                    }
                  } catch (err) {
                    set_err(err.message);
                  }
                }}
              />
            </div>
            <hr className="my-2" />
            <table className={Array2D_Tablecn.table_cn}>
              <tbody>
                <tr className={Array2D_Tablecn.tr_cn}>
                  <td className={Array2D_Tablecn.td_cn}>
                    <p className="whitespace-pre-wrap">Quantity</p>
                  </td>
                  <td className={Array2D_Tablecn.td_cn}>
                    <p className="whitespace-pre-wrap">{qty} qty.</p>
                  </td>
                </tr>
                <tr className={Array2D_Tablecn.tr_cn}>
                  <td className={Array2D_Tablecn.td_cn}>
                    <p className="whitespace-pre-wrap">LootBox Cost</p>
                  </td>
                  <td className={Array2D_Tablecn.td_cn}>
                    <p className="whitespace-pre-wrap">
                      {dec(base.price, 6)} {base.token}
                    </p>
                  </td>
                </tr>
                <tr className={Array2D_Tablecn.tr_cn}>
                  <td className={Array2D_Tablecn.td_cn}>
                    <p className="whitespace-pre-wrap">Total</p>
                  </td>
                  <td className={Array2D_Tablecn.td_cn}>
                    <p className="whitespace-pre-wrap">
                      {`${dec(base.price * qty, 6)} ${base.token}`}
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>

            <div className="fr-cc my-2">
              {loading ? (
                <>
                  <Loader01c size="s" />
                  <Tag className="">
                    {aumode == "thirdweb"
                      ? "Minting your boxes..."
                      : "please confirm transaction on MetaMask..."}
                  </Tag>
                </>
              ) : (
                <Tag
                  onClick={clickpayment}
                  className="fr-cc gap-2 bg-acc4/40 text-white transform -skew-x-12"
                >
                  <span>Pay Now</span>
                </Tag>
              )}
            </div>
          </div>
        </Card>
      </PopUp>
    </>
  );
};

const NonMintSignup = ({ preid }) => {
  const [sigreqstate, set_ssigreqstate] = useState(0);
  const submit_signup_request = async () => {
    try {
      set_ssigreqstate(1);
      await cdelay(2 * 1e3);
      let d = {
        twitter: extract_inp(`${preid}:nonmint_twitter`),
        discord: extract_inp(`${preid}:nonmint_discordname`),
        ethaddress: extract_inp(`${preid}:nonmint_ethaddress`),
        reqqty: extract_inp(`${preid}:nonmint_reqqty`),
      };
      console.log("d", d);
      if (_.isEmpty(d)) throw new Error("empty data");
      let r = await q_corelootbox_v2_signup_request(d).queryFn();

      if (r.status !== "success") {
        throw new Error(r.err);
      } else {
        set_val_inp(`${preid}:nonmint_twitter`, "");
        set_val_inp(`${preid}:nonmint_discordname`, "");
        set_val_inp(`${preid}:nonmint_ethaddress`, "");
        set_val_inp(`${preid}:nonmint_reqqty`, "");
      }

      console.log("r", r);
      set_ssigreqstate(2);
    } catch (err) {
      set_ssigreqstate(-1);
    }
    setTimeout(() => {
      set_ssigreqstate(0);
    }, 2000);
  };

  return (
    <div className="xs:w-full lg:w-[50rem] mx-auto bg-r2dark/80 font-digi resp-text-0 text-acc4 my-2 border border-acc4 rounded-md p-2">
      <div class="text-center">
        The Mint is currently not open if you would like to be added to our
        whitelist please sign up below!!{" "}
      </div>
      <div class="grid grid-cols-12 resp-gap-2 flex-wrap">
        <div class="col-span-6">
          <InpText
            id={`${preid}:nonmint_twitter`}
            label="Twitter Name"
            contprops={{ className: "w-full border-acc4" }}
            inpprops={{ className: "w-full" }}
            placeholder=""
            autoComplete="off"
          />
        </div>
        <div class="col-span-6">
          <InpText
            id={`${preid}:nonmint_discordname`}
            label="Discord Name"
            contprops={{ className: "w-full border-acc4" }}
            inpprops={{ className: "w-full" }}
            placeholder=""
            autoComplete="off"
          />
        </div>

        <div class="col-span-9">
          <InpText
            id={`${preid}:nonmint_ethaddress`}
            label="ETH Address"
            contprops={{ className: "w-full border-acc4" }}
            inpprops={{ className: "w-full" }}
            placeholder=""
            autoComplete="off"
          />
        </div>

        <div class="col-span-3">
          <InpText
            id={`${preid}:nonmint_reqqty`}
            label={<span class="resp-text--1">{"Requested Boxes"}</span>}
            contprops={{ className: "w-full border-acc4" }}
            inpprops={{ className: "w-full" }}
            placeholder=""
            autoComplete="off"
          />
        </div>
      </div>
      <div class="fr-cc">
        <div class="xs:hidden lg:flex fr-cc">
          <ContactTag
            icontagcn="shadow-md shadow-acc4 text-acc4 xs:text-[1rem] lg:text-[2.5rem]"
            textcn="text-acc4"
            link="https://twitter.com/Spotonparts"
            icon={faTwitter}
            txt="@Spotonparts"
          />
          <ContactTag
            icontagcn="shadow-md shadow-acc4 text-acc4 xs:text-[1rem] lg:text-[2.5rem]"
            textcn="text-acc4"
            link={"https://discord.gg/j4k3NdY6St"}
            icon={faDiscord}
            txt={"Join Discord Now"}
          />
        </div>
        <div class="xs:flex lg:hidden fc-ss">
          <ContactTag
            icontagcn="shadow-md shadow-acc4 text-acc4 xs:text-[1rem] lg:text-[2.5rem]"
            textcn="text-acc4"
            link="https://twitter.com/Spotonparts"
            icon={faTwitter}
            txt="@Spotonparts"
          />
          <ContactTag
            icontagcn="shadow-md shadow-acc4 text-acc4 xs:text-[1rem] lg:text-[2.5rem]"
            textcn="text-acc4"
            link={"https://discord.gg/j4k3NdY6St"}
            icon={faDiscord}
            txt={"Join Discord Now"}
          />
        </div>
        <div class="h-[4rem] w-[0.1rem] bg-acc4/60 mx-4"></div>
        {sigreqstate == 2 ? (
          <FontAwesomeIcon
            icon={faCheck}
            className="text-green-400 resp-text-3"
          />
        ) : sigreqstate == 1 ? (
          <Loader01c size="s" />
        ) : (
          <Tag
            onClick={submit_signup_request}
            className="bg-acc4/50 -skew-x-12 font-digi resp-text--1 text-white"
          >
            <span className="resp-text-1">Submit</span>
          </Tag>
        )}
      </div>
    </div>
  );
};

const BannerSection = () => {
  const raritychance = [
    ["F1", "METAL", 210],
    ["F2", "METAL", 375],
    ["F3", "FIRE", 500],
    ["F4", "FIRE", 625],
    ["F5", "EARTH", 800],
    ["F6", "EARTH", 900],
    ["F7", "WATER", 1300],
    ["F8", "WATER", 1800],
  ];

  const [qobase] = useQueries([
    q_corelootbox_v2_base({
      staleTime: 1 * 60 * 1e3,
      refetchInterval: 1 * 60 * 1e3,
    }),
  ]);

  const base = useMemo(() => {
    if (!qissuccesss(qobase)) return null;
    return getv(qobase, "data.result");
  }, [qobase.dataUpdatedAt]);

  const mmcon = useMetaMaskContext();
  const aucon = useAuthContext();
  const laycon = useLayoutContext();
  const { auth, vault, aumode } = aucon;

  const { enablebuy } = useMintContext();
  useEffect(() => {
    if (enablebuy) qobase.refetch();
  }, [enablebuy]);
  const { now } = useNowContext();

  return (
    <div className="">
      <div className="grid sm:grid-cols-2 md:grid-cols-6 max-w-[98vw] w-full">
        <div className="sm:col-span-2 md:col-span-3 realtive w-full mx-auto">
          <div className="fc-cc w-full flex-wrap relative">
            <div
              className={twMerge(
                "md:w-[15rem] md:h-[15rem]  md:top-[2rem] md:left-[2rem]",
                "xs:w-[8rem] xs:h-[12rem]  xs:top-[1rem] xs:left-[1rem]",
                "absolute translate-x-[50%] translate-y-[50%] bg-acc4 z-[0] blur-3xl",
              )}
            ></div>
            <div className="mint-box-container xs:mt-[50px] md:mt-[133px]">
              <div
                className={twMerge(
                  "md:top-[-5rem] md:h-[12rem]",
                  "xs:top-[-2rem] xs:h-[6rem]",
                  "img-obey-cont rotate-[-20deg] absolute",
                )}
              >
                <img src="https://i.imgur.com/5LJPt3M.png" />
              </div>
              <div className="xs:h-[5rem] md:h-[7rem]"></div>
              <p className="resp-text-2 font-digi resp-my-4">Core Mint</p>
              <div className="fr-cc">
                <div className=""></div>
                {qissuccesss(qobase) && (
                  <>
                    <div className="fc-cc gap-0 px-4 border-x-2 border-acc4">
                      <p className="leading-4	text-white resp-text-2 font-mon font-bold ">
                        {dec(base.price, 2)}
                        <span className="resp-text-1"> {base.token}</span>
                      </p>
                      <p className="leading-4	resp-text-1 font-thin resp-my-2">
                        price
                      </p>
                    </div>
                  </>
                )}
                <div className=""></div>
              </div>
              {enablebuy ? (
                <>
                  {auth == false && (
                    <Tag
                      onClick={() => {
                        laycon.open_loginpop();
                      }}
                      className=" mt-4 mb-2 px-4 py-2 bg-acc4/80 text-black fr-cc gap-2"
                    >
                      <Img
                        className={"xs:w-[0.9rem] md:w-[1.5rem]"}
                        img={metamaskimg}
                      />
                      <span>Connect Wallet</span>
                    </Tag>
                  )}
                  {auth == true && qissuccesss(qobase) && (
                    <>
                      {base.available > 0 ? (
                        <Buy {...{ base }} />
                      ) : (
                        <Tag className="bg-red-400 tex-black rounded-md italic ">
                          Out of Stock!!!
                        </Tag>
                      )}
                    </>
                  )}
                </>
              ) : (
                <>
                  {now < nano(st_countdown) ? (
                    <div className="fc-cc p-2 font-digi text-[1.2rem]">
                      <p>Starts in</p>
                      <p className="">{from_time_mini(st_countdown, true)}</p>
                    </div>
                  ) : null}
                </>
              )}
              <div className="fr-sc w-full">
                <div className="flex-1"></div>
                {!qissuccesss(qobase) ? (
                  false
                ) : nano(ed_countdown) > now ? (
                  <p className="text-red-400 italic font-bold my-2 resp-text--1">
                    mint closes in {from_time_mini(ed_countdown, true)}
                  </p>
                ) : null}
              </div>
            </div>
          </div>
          <div class="xs:block lg:hidden">
            <NonMintSignup preid="xs" />
          </div>
        </div>

        <div className="sm:col-span-2 md:col-span-3 p-4  w-full mx-auto">
          <div className="fc-cc w-full flex-wrap">
            <div className="h-[2rem]"></div>
            <div className="resp-text-2 font-digi">Buy A NFT Mint Box </div>

            <div className="p-2">
              <p className="mid-head-text">
                <span className="resp-text-1">Rarity Chance</span>
              </p>
              <table className=" border-b border-slate-500  xs:w-[12rem] md:min-w-[20rem]  resp-my-2 resp-text--1">
                <thead>
                  <tr>
                    <th className="border-b border-acc4/40 text-acc4 px-2 md:pl-6">
                      GENERATION
                    </th>
                    <th className="border-b border-acc4/40 text-acc4 px-2 md:pl-6">
                      ELEMENT
                    </th>
                    <th className="border-b border-acc4/40 text-acc4 px-2 md:pl-6">
                      Count
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {raritychance.map((r) => {
                    return (
                      <tr>
                        {r.map((d) => (
                          <td className="border-b border-slate-600 resp-px-2 md:pl-6 py-1 text-left xs:min-w-[2rem] md:min-w-[5rem] resp-text--1">
                            {d}
                          </td>
                        ))}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              <p className="base-text">
                F1 assets have, on average, more strength than F8+ assets.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="xs:hidden lg:block">
        <NonMintSignup preid="lg" />
      </div>
    </div>
  );
};

const boximg = "https://i.imgur.com/5LJPt3M.png";
export const ClosedBoxes = ({ closed_n }) => {
  const mmcon = useMetaMaskContext();
  const aucon = useAuthContext();
  const { auth, vault, aumode } = aucon;
  const t3con = useThirdWebLoginContext();
  const { thirdweb_client, active_account } = t3con;

  const mintcon = useMintContext();
  const { upd_closed_n } = mintcon;

  const [resp, set_resp] = useState({});
  const [popup, set_popup] = useState(false);
  const [loading, set_loading] = useState(false);

  const openbox = async () => {
    set_loading(true);
    const lootcon =
      aumode === "thirdweb"
        ? await CoreLootbox_v2.get_contract({
            rpc: polygon.rpc,
            nosigner: true,
          })
        : await CoreLootbox_v2.get_contract();
    let resp = null;
    if (aumode === "thirdweb") {
      resp = await t3_contract_call(
        "corelootboxv2",
        "openLootbox",
        [1],
        "txn",
        true,
        { active_account },
      );
      await cdelay(2 * 1e3);
    } else {
      await lootcon.openLootbox(1, {});
      resp = await resp.wait();
      await cdelay(2 * 1e3);
    }
    console.log(resp);

    await q_que_txn({
      hash: resp.hash,
      vault,
      service: "corelootbox",
      type: "open",
    }).queryFn();

    await cdelay(5000);
    await upd_closed_n();
    set_loading(false);
  };

  // useEffect(() => {
  //   console.log(resp);
  // }, [resp]);

  if (closed_n == 0)
    return (
      <>
        <p className="text-acc4 resp-text-0">
          No Closed LootBoxes owned currently
        </p>
      </>
    );
  return (
    <div className=" rounded-md bg-r2dark/50 fr-cc w-max">
      <Card className={twMerge("p-4 pl-[2rem] overflow-hidden w-max")}>
        <div className="fr-cc">
          <div className="relative xs:w-[6rem] md:w-[12rem] xs:h-[6rem] md:h-[12rem] mx-auto ">
            <div className="absolute animate-fade-in xs:w-[6rem] md:w-[12rem] xs:h-[6rem] md:h-[12rem] translate-x-[-50%] translate-y-[-50%] top-[50%] left-[50%] bg-acc4 z-[5] blur-3xl"></div>
            <Img
              img={boximg}
              className={
                "absolute xs:w-[6rem] md:w-[12rem] xs:h-[6rem] md:h-[12rem] z-[10]"
              }
            />
          </div>
          <div className="p-4">
            <p className="text-acc4 font-digi  resp-text-2">x {closed_n}</p>
          </div>
        </div>
        <div className="fr-sc my-4">
          <div className="flex-1"></div>
          {loading && <Loader01c size="s" />}
          <Tag
            onClick={() => {
              if (loading) return;
              openbox();
            }}
            className="bg-acc4 -skew-x-12 text-black font-digi"
          >
            Open 1 Box
          </Tag>
        </div>
        {!nils(resp.err) && (
          <div className="fr-sc my-4">
            <p className="text-red-400 resp-text--1">{!nils(resp.err)}</p>
          </div>
        )}
      </Card>
    </div>
  );
};

const ViewLootBoxNFTs = ({ lootid }) => {
  const [qo] = useQueries([
    q_corelootbox_v2_lootnfts(
      { lootid },
      {
        staleTime: 2 * 60 * 1e3,
        refetchInterval: 2 * 60 * 1e3,
      },
    ),
  ]);
  const l = useMemo(() => {
    if (qissuccesss(qo)) return getv(qo, "data.result");
    return null;
  }, [qo.dataUpdatedAt]);
  return (
    <div className="my-2 border-b border-acc4/40 py-2">
      <p className="p-2 text-acc4 resp-text-1">LootBox#{lootid}</p>
      {qo.isLoading ? (
        <div className="fr-cc text-acc4 gap-2">
          <Loader01c size="s" />
          <span className="resp-text-1">Loading your Lootbox minted NFTs</span>
        </div>
      ) : qiserr(qo) ? (
        <div className="fr-cc text-red-400 gap-2">
          <span className="resp-text-1">Error: {qiserr(qo)}</span>
        </div>
      ) : qissuccesss(qo) ? (
        <>
          <div className="grid xs:grid-cols-1 gap-2 flex-wrap">
            {
              // [...l?.assets, { type: "god", tokenId: 1 }] ||
              (l?.assets || []).map((a) => {
                const hash =
                  a.tx_hash || a.type == "fbike"
                    ? getv(a, "data.mint.tx_hash")
                    : a.type == "god"
                      ? getv(a, "data.tx_hash")
                      : null;

                const date =
                  a.date || a.type == "fbike"
                    ? getv(a, "data.mint.date")
                    : a.type == "god"
                      ? getv(a, "data.date")
                      : null;

                let txrow = (
                  <>
                    {nils(hash) ? (
                      <>
                        <Loader01c size="s" />
                        <span className="text-acc4 resp-text-0">
                          Awaiting Mint...
                        </span>
                      </>
                    ) : (
                      <>
                        <Link
                          className="fr-sc gap-1"
                          target="_blank"
                          to={polymaintxnlink(hash)}
                        >
                          <Img className={"w-[2rem] h-[2rem]"} img={polyimg} />
                          <span className="text-purple-300 resp-text-0">
                            {hash.slice(0, 5)}..
                          </span>
                        </Link>
                        <span className="resp-text-0">
                          Minted on {iso_format(date)}
                        </span>
                      </>
                    )}
                  </>
                );
                if (a.type == "fbike")
                  return (
                    <div className="p-2 bg-dark rounded-md ">
                      <div className="fr-cc gap-2 p-2 px-4 resp-text-0 xs:hidden md:flex">
                        <span>TokenID: {a.tokenId ?? "--"}</span>
                        <div className="flex-1"></div>
                        {txrow}
                      </div>

                      <div className="fc-ss gap-2 p-2 px-4 resp-text-0 xs:flex md:hidden">
                        <span className="resp-my-2">
                          TokenID: {a.tokenId ?? "--"}
                        </span>
                        {txrow}
                      </div>
                      <BikeCard bike={getv(a, "data")} />
                    </div>
                  );
                else if (a.type == "god") {
                  return (
                    <div className="p-2 bg-dark rounded-md ">
                      <div className="fr-cc gap-2 p-2 px-4 resp-text-0 xs:hidden md:flex">
                        <span>TokenID: {a.tokenId ?? "--"}</span>
                        <div className="flex-1"></div>
                        {txrow}
                      </div>

                      <div className="fc-ss gap-2 p-2 px-4 resp-text-0 xs:flex md:hidden">
                        <span className="resp-my-2">
                          TokenID: {a.tokenId ?? "--"}
                        </span>
                        {txrow}
                      </div>
                      <div className="grid grid-cols-3">
                        <div className="col-span-1">
                          <Img
                            className={"w-full"}
                            img="https://imgur.com/lYds0vd.png"
                          />
                        </div>
                        <div className="col-span-2">
                          <p className="text-acc4 font-digi text-[1.5rem] mt-[2rem] ml-[2rem]">
                            $GOD NFT
                          </p>
                        </div>
                      </div>
                    </div>
                  );
                }
              })
            }
          </div>
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

const TxRow = ({ tx }) => {
  const [nftspopup, set_nftspopup] = useState(false);

  let date =
    moment().diff(tx.date, "seconds") < 5 * 60
      ? moment(tx.date).fromNow()
      : iso_format(tx.date, "DD-MMM YY, h:mm:ss a");
  let hash = tx.id.split(":")[0];

  const td_amt = (
    <div className="fr-sc resp-gap-1 resp-text--2">
      <div className="flex-1"></div>
      <div className="xs:w-[1rem] lg:w-[2rem]">
        <Img img={wethimg} />
      </div>
      <span>{dec(tx.amt, 4)}</span>
    </div>
  );

  const td_polyimg = (
    <Tag redirect={polytxnlink(hash)} className="xs:w-[1rem] lg:w-[3rem]">
      <Img img={polychainimg} />
    </Tag>
  );

  const td_names = <div className="fc-ss resp-gap-1 resp-text--2"></div>;

  const td_type = (
    <span className="resp-text--2">
      {_.chain(tx.type).split("_").map(_.upperCase).join(" ").value()}
    </span>
  );

  const td_date = <span className="resp-text--2">{date}</span>;

  const qty = getv(tx, "connects.qty");
  const td_inout = (
    <>
      {tx.type == "mint_corelootbox" && (
        <Tag className="bg-purple-500/20 text-white">{qty} boxes Minted</Tag>
      )}
      {tx.type == "open_corelootbox" && (
        <Tag className="bg-yellow-500/20 text-white">{qty} boxes Opened</Tag>
      )}
    </>
  );

  const ls = getv(tx, "connects_post.lootids");

  const td_view = (
    <>
      {tx.type == "open_corelootbox" && (
        <>
          <Tag
            onClick={() => {
              set_nftspopup(true);
            }}
            className="bg-acc4/40 transform -skew-x-12 text-white gap-1 fr-cc"
          >
            <span>View NFTs</span>
            <FontAwesomeIcon icon={faChevronRight} />
          </Tag>

          <PopUp
            wrapcn={twMerge("top-[5rem] left-[50%]")}
            innercn={twMerge(" translate-x-[-50%] translate-y-[0%]")}
            openstate={nftspopup}
            overlayclose={false}
          >
            <Card className={"xs:w-[90vw] md:w-[50rem] max-w-[95rem] relative"}>
              <div className="fr-cc">
                <div className="flex-1"></div>
                <Tag
                  onClick={() => set_nftspopup(false)}
                  className={twMerge(
                    "fc-cc rounded-full bg-red-500 ",
                    "absolute md:right-[-1rem] md:top-[-1rem] md:w-[2rem] md:h-[2rem]",
                    "absolute xs:right-[-0.5rem] xs:top-[-0.5rem] xs:w-[1rem] xs:h-[1rem]",
                  )}
                >
                  <FontAwesomeIcon icon={faClose} />
                </Tag>
              </div>
              {ls.map((lootid) => {
                return <ViewLootBoxNFTs {...{ key: lootid, lootid }} />;
              })}
            </Card>
          </PopUp>
        </>
      )}
    </>
  );

  return (
    <>
      <tr className={"thintdrow xs:hidden md:block"}>
        <td className={""}>{td_polyimg}</td>
        <td className={""}>{td_type}</td>
        <td className={""}>{td_amt}</td>
        <td className={""}>{td_date}</td>
        <td className={""}>{td_inout}</td>
        <td className={""}>{td_view}</td>
      </tr>
      <tr
        className={"thintdrow xs:block md:hidden border-b border-transparent"}
      >
        <td className={""} colSpan={2}>
          {td_polyimg}
        </td>
        <td className={""} colSpan={2}>
          {td_amt}
        </td>
        <td className={""} colSpan={2}>
          {td_date}
        </td>
      </tr>
      <tr className={"thintdrow xs:block md:hidden border-b border-acc4"}>
        <td className="" colSpan={6}>
          <div className="fr-sc">
            {td_inout}
            {td_view}
          </div>
        </td>
      </tr>
    </>
  );
};

const Transactions = ({ txns }) => {
  return (
    <Card className={"w-max mx-auto card-basic-bg card-grad-border"}>
      {_.isEmpty(txns) ? (
        <p>No Transactions Yet</p>
      ) : (
        <table className={Array2D_Tablecn.table_cn}>
          <tbody>
            {(txns || []).map((tx) => {
              return <TxRow tx={tx} />;
            })}
          </tbody>
        </table>
      )}
    </Card>
  );
};

function MintV2Page() {
  const appcon = useAppContext();
  const { psearch } = appcon;

  const mmcon = useMetaMaskContext();
  const aucon = useAuthContext();
  const { auth, aumode } = aucon;

  const vault = useMemo(() => {
    if (!nils(psearch.v)) return psearch.v.toLowerCase();
    return aucon.vault;
  }, [psearch.v, aucon.vault]);

  const t3con = useThirdWebLoginContext();
  const { thirdweb_client, active_account } = t3con;

  const { now } = useNowContext();
  const enablebuy =
    nils(st_countdown) && nils(ed_countdown)
      ? true
      : _.inRange(now, nano(st_countdown), nano(ed_countdown));

  const [closed_n, set_closed_n] = useState(null);
  const upd_closed_n = async () => {
    try {
      if (!auth) return null;
      let lootcon =
        aumode === "thirdweb"
          ? await CoreLootbox_v2.get_contract({
              rpc: polygon.rpc,
              nosigner: true,
            })
          : await CoreLootbox_v2.get_contract();
      let bal = await lootcon.accountOwns(vault);
      bal = Number(bal);
      set_closed_n(bal);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    setTimeout(() => {
      upd_closed_n();
    }, 3000);
  }, [auth]);

  const [qotxns] = useQueries([
    q_corelootbox_v2_txns(
      { vault },
      {
        staleTime: 10 * 1e3,
        refetchInterval: 10 * 1e3,
      },
    ),
  ]);
  const txns = useMemo(() => {
    upd_closed_n();
    if (qissuccesss(qotxns)) return getv(qotxns, "data.result");
    return [];
  }, [qotxns.dataUpdatedAt]);

  const mintcon = {
    vault,
    enablebuy,
    upd_closed_n,
    closed_n,
    qotxns,
    txns,
  };
  return (
    <MintContext.Provider value={mintcon}>
      <Helmet>
        <title>Core Mint || DNA Racing</title>
      </Helmet>
      <div className="relative">
        <div className="xs:hidden md:block max-h-[calc(800px)] h-[calc(100vh-9rem)] ">
          <div className="">
            <div className="hero-section">
              <div className="gradient-overlay"></div>
              <div className="h-[3rem]"></div>
              <div className="html-overlay relative">
                <div className="home-container">
                  <BannerSection />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="xs:block md:hidden">
          <div className="home-container">
            <BannerSection />
          </div>
        </div>
        {auth && (
          <div className="home-container">
            <div className="h-[2rem]"></div>
            <LootBoxesList />
            <div className="h-[2rem]"></div>
            <div className="h-[2rem]"></div>
          </div>
        )}
      </div>
    </MintContext.Provider>
  );
}

const LootBoxesList = () => {
  const mintcon = useMintContext();
  const { closed_n, txns } = mintcon;
  return (
    <div className="">
      <div className="fr-sc my-2 gap-2">
        <p className="text-acc4 font-digi resp-text-2">LootBoxes</p>
      </div>
      <p className="text-acc4 italic  resp-text-1">Closed Boxes</p>
      <ClosedBoxes closed_n={closed_n} />

      <div className="h-[2rem]"></div>

      <p className="text-acc4 italic  resp-text-1">Transactions</p>
      <Transactions {...{ txns }} />
    </div>
  );
};

export default MintV2Page;
